import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import Seo from '../../components/seo';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/layout/layout';
import HeroSection from './components/hero-section';
import WhySection from './components/why-section';
import FirstTutorialSection from './components/first-tutorial-section';
import LawyersSection from './components/lawyers-section';
import { graphql, useStaticQuery } from 'gatsby';
import getCurrentTranslations from '../../components/contentful-translator';

const DocumentsPage = () => {
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);
  const data = useStaticQuery(graphql`
    query {
      allContentfulDocuments(sort: { fields: createdAt }) {
        edges {
          node {
            title
            category
            language
            source
            tags
            description
            file {
              file {
                url
              }
            }
            node_locale
          }
        }
      }
    }
  `);

  const [documents, setDocuments] = useState();
  const [tutorial, setTutorial] = useState();

  useEffect(() => {
    const getDocuments = () => {
      const getDocuments = getCurrentTranslations(
        data.allContentfulDocuments.edges,
        language
      );
      setDocuments(getDocuments);
      const firstTutorial = getDocuments.find(
        (tutorial) => tutorial.node.category === 'Poradnik'
      );
      setTutorial(firstTutorial);
    };
    getDocuments();
  }, [data.allContentfulDocuments, language]);

  return (
    <Layout withHeader={false}>
      <Seo
        title={t`documents-page.seo-title`}
        description={t`documents-page.seo-description`}
      />
      <HeroSection />
      <WhySection />
      {tutorial && <FirstTutorialSection tutorial={tutorial} />}
      <LawyersSection />
    </Layout>
  );
};

export default DocumentsPage;
