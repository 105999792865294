import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  BottomContainer,
  BottomTextContainer,
  DescriptionContainer,
  DescriptionText,
  FirstTutorialContainer,
  NameText,
  PositionText,
  RelativeContainer,
  SignatureContainer,
  TextContainer
} from './styled.components';
import { StaticImage } from 'gatsby-plugin-image';
import { H2Style, WhiteButton } from '../../styled.components';
import { Trans } from 'react-i18next';
import DocumentsModal from '../../../../components/modals/documents/DocumentsModal';

const FirstTutorialSection = ({ tutorial }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tutorialData, setTutorialData] = useState(null);

  const openModal = () => {
    setTutorialData(tutorial);
    setIsModalOpen(true);
  };

  return (
    <>
      <DocumentsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        pdfDocument={tutorialData}
      />
      <FirstTutorialContainer>
        <RelativeContainer>
          <StaticImage
            className="jakub-image"
            src="../../../../images/documents-page/first-tutorial/documents-jakub.webp"
            alt="Jakub image"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="circles-up"
            src="../../../../images/documents-page/first-tutorial/circles-up.webp"
            alt="Circles up"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="circles-down"
            src="../../../../images/documents-page/first-tutorial/circles-down.webp"
            alt="Circles down"
            placeholder="none"
            loading="lazy"
          />
        </RelativeContainer>
        <TextContainer>
          <H2Style>
            <Trans
              i18nKey="documents-page.first-tutorial-title"
              components={{
                blue: <span className="blue-header"></span>
              }}
            />
          </H2Style>
          <BottomContainer>
            <BottomTextContainer>
              <DescriptionContainer>
                <DescriptionText>{t`documents-page.first-tutorial-description-1`}</DescriptionText>
                <DescriptionText>{t`documents-page.first-tutorial-description-2`}</DescriptionText>
              </DescriptionContainer>
              <SignatureContainer>
                <NameText>{t`documents-page.first-tutorial-name`}</NameText>
                <PositionText>{t`documents-page.first-tutorial-position`}</PositionText>
              </SignatureContainer>
            </BottomTextContainer>
            <WhiteButton
              onClick={
                openModal
              }>{t`documents-page.first-tutorial-button`}</WhiteButton>
          </BottomContainer>
        </TextContainer>
      </FirstTutorialContainer>
    </>
  );
};

export default FirstTutorialSection;
