import styled from 'styled-components';

export const DocumentsModalFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

export const UpContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  box-sizing: border-box;
  width: 100%;

  .close-image {
    cursor: pointer;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;

  .iot-image img {
    max-height: 350px;
    align-self: stretch;
  }

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SmallTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 700px) {
    display: none;
  }
`;

export const RightFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;

  @media only screen and (max-width: 700px) {
    margin-bottom: 24px;
  }
`;

export const InputForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
  width: 100%;

  label {
    color: var(--UI-Black, #080e14);
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;

    span {
      color: var(--error, #ff0c0c);
    }
  }

  input {
    padding: 14px 17px;
    height: 55px;
    box-sizing: border-box;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid var(--UI-Dark-Blue, #0f5575);
    background: #fff;
    color: var(--UI-Black, #080e14);
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.36px;

    &::placeholder {
      color: var(--UX-Grey-2, #c4c4c4);
    }
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const CheckboxLabel = styled.label`
  cursor: pointer;
  position: relative;
  padding-left: 32px !important;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    left: 2px;
    height: 22px;
    width: 22px;
    box-sizing: border-box;
    background-color: white;
    border: 2px solid #5fa5c5;
    border-radius: 4px;
  }

  input:checked ~ .checkmark {
    background-color: #5fa5c5;
    border: 2px solid #5fa5c5;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 6.5px;
    top: 4px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 3px 3px 0;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .error {
    color: var(--error, #ff0c0c);
  }
`;

export const ErrorParagraph = styled.p`
  color: var(--error, #ff0c0c);
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const DownContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  border-top: 1px solid var(--UI-Grey-blue, #c4dfec);
  background: var(--UI-Blue-Tint, #f5fafd);

  @media only screen and (max-width: 700px) {
    position: fixed;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    width: 100%;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  height: 55px;
  box-sizing: border-box;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 5px;
  border: 2px solid var(--UI-Dark-Blue, #0f5575);
  background: var(--UI-Light-Blue, #fff);

  color: var(--UI-Dark-Blue, #0f5575);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.32px;

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    border: 2px solid var(--UI-Dark-Blue, #4ebfee);
    background: var(--UI-Light-Blue, #4ebfee);
    color: #fff;
  }

  @media only screen and (max-width: 700px) {
    padding: 4px 16px;

    &:hover {
      background: var(--UI-Light-Blue, #fff);
      color: var(--UI-Dark-Blue, #0f5575);
    }
  }
`;

export const DownloadButton = styled.button`
  display: flex;
  height: 55px;
  box-sizing: border-box;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 5px;
  border: 2px solid var(--UI-Dark-Blue, #0f5575);
  background: var(--UI-Light-Blue, #0f5575);

  color: var(--UI-Dark-Blue, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.32px;

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: var(--UI-Light-Blue, #fff);
    color: #0f5575;
  }

  @media only screen and (max-width: 700px) {
    padding: 4px 16px;

    &:hover {
      background: var(--UI-Light-Blue, #0f5575);
      color: var(--UI-Dark-Blue, #fff);
    }
  }
`;
