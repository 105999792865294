import * as Yup from 'yup';

export const getDocumentsValidationSchema = (t) => {
  const schema = Yup.object().shape({
    name: Yup.string().required(t`documents-modal.required`),
    email: Yup.string()
      .required(t`documents-modal.required`)
      .email(t`documents-modal.invalid-email`),
    phone: Yup.string()
      .required(t`documents-modal.required`)
      .matches(/^\+[0-9]{1,4}[0-9]{9}$/, t`documents-modal.invalid-phone`)
      .min(10, t`documents-modal.invalid-phone`),
    organizationType: Yup.string().required(t`documents-modal.required`),
    organizationName: Yup.string().required(t`documents-modal.required`),
    position: Yup.string().required(t`documents-modal.required`),
    policy: Yup.boolean()
      .required(t`documents-modal.required`)
      .oneOf([true], t`documents-modal.required`)
  });

  return { schema };
};
