import styled from 'styled-components';
import heroImage from '../../../../images/documents-page/hero/documents-hero-image.webp';

export const HeroContainer = styled.div`
  margin-top: var(--menu-height);
  overflow: hidden;
  background-size: cover !important;
  background-position: center;
  width: 100%;
  background-image: url(${heroImage});
  display: block;
  background-attachment: scroll;

  @media only screen and (max-width: 900px) {
    background-image: none;
    background: linear-gradient(267deg, #6ebde9 2.07%, #0f5575 97.53%);
  }
`;

export const HeroLeftContainer = styled.div`
  display: flex;
  padding: 60px 0px;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  align-items: flex-start;
  max-width: 570px;
`;

export const HeroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
`;

export const HeroDescription = styled.p`
  color: #fff;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
`;
