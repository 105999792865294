import {
  AmiSection,
  AmiSectionContainer,
  AmiSectionHeader,
  Column,
  HeaderWrapper,
  Text
} from '../../styled.components';

import ASULogo from '../../../../images/ami/asu.png';
import AstraLogo from '../../../../images/ami/astra-zeneca2.png';
import DDSoftwareLogo from '../../../../images/ami/ddsoftware.png';
import EconverseLogo from '../../../../images/ami/econverse.png';
import ForumLogo from '../../../../images/ami/forum-logo2.png';
import { GalleryComponent } from '../../../../components/galeria';
import { HeaderContent } from './AmiAgenda';
import MedmeLogo from '../../../../images/ami/medme.png';
import React from 'react';
import SKNLogo from '../../../../images/ami/skn.png';
import { SectionWrapper } from '../../../../components/global/flexbox';
import { respondTo } from '../../../../utils/breakpoints';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export default function Gallery(props) {
  const galleryComponent = Object.values(props);
  const { t } = useTranslation('ami');
  return (
    <>
      <AmiSection style={{ backgroundColor: '#F9F8FC' }}>
        <SectionWrapper>
          <AmiSectionContainer className="column p-80">
            <HeaderContent className="flex-start">
              <AmiSectionHeader className="purple">
                {t('gallerySection.title')}
              </AmiSectionHeader>
              <Text className="weight-500">{t('gallerySection.text')}</Text>
            </HeaderContent>
            <GalleryComponent gallery={galleryComponent} isNotGatsby={false} />
          </AmiSectionContainer>
        </SectionWrapper>
      </AmiSection>
      <AmiSection className="partners">
        <SectionWrapper>
          <AmiSectionContainer className="column p-80">
            <HeaderWrapper>
              <AmiSectionHeader className="purple">
                {t('partnersSection.title')}
              </AmiSectionHeader>
            </HeaderWrapper>
            <Column>
              <SmallHeader>{t('partnersSection.main')}</SmallHeader>
              <PartnersRow>
                <PartnerImage
                  className="big"
                  src={AstraLogo}
                  alt="Astra Zeneca Logo"
                />
                <PartnerImage className="big" src={ForumLogo} alt="Forum" />
              </PartnersRow>
            </Column>
            <PartnersRow>
              <Column>
                <SmallHeader>{t('partnersSection.patron')}</SmallHeader>
                <PartnerImage src={MedmeLogo} alt="Chamber Logo" />
              </Column>
              <Column>
                <SmallHeader>{t('partnersSection.substantive')}</SmallHeader>
                <PartnerImage src={DDSoftwareLogo} alt="DD Software" />
              </Column>
              <Column>
                <SmallHeader>{t('partnersSection.ecosystem')}</SmallHeader>
                <PartnerImage
                  src={ASULogo}
                  alt="Akademia Samorządów Uczniowskich"
                />
              </Column>
            </PartnersRow>
            <Column>
              <SmallHeader>{t('partnersSection.co-organizer')}</SmallHeader>
              <PartnersRow>
                <img height={90} src={EconverseLogo} alt="DD Software" />
                <img
                  height={90}
                  src={SKNLogo}
                  alt="Akademia Samorządów Uczniowskich"
                />
              </PartnersRow>
            </Column>
          </AmiSectionContainer>
        </SectionWrapper>
      </AmiSection>
    </>
  );
}

const PartnersRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  ${respondTo.m`
    flex-direction: row;
    gap: 60px;
  `}
`;

const SmallHeader = styled.h3`
  font: ${(props) => props.theme.typography.headers.h4};
  color: ${(props) => props.theme.colors.uiPurple};
  margin: 0;
  text-align: center;
`;

const PartnerImage = styled.img`
  width: auto;
  height: 70px;
  ${respondTo.m`
    height: 90px;
    width: auto;
  `}
  &.big {
    width: auto;
    height: 90px;
    ${respondTo.m`
      height: 150px;
      width: auto;
    `}
  }
`;
