import {
  AmiContainer,
  AmiHeroContainer,
  AmiHeroContentWrapper,
  AmiSection,
  Column,
  HeroSection,
  HeroTitle,
  Lead,
  OverTitle,
  Row,
  Text
} from '../../styled.components';

import AmiLogo from '../../../../images/ami/ami-logo.png';
import AstraZenecaLogo from '../../../../images/ami/astra-zeneca.png';
import ForumLogo from '../../../../images/ami/forum-logo.png';
import GirlsInnovatorImage from '../../../../images/ami/girls-innovator.png';
import React from 'react';
import { SectionWrapper } from '../../../../components/global/flexbox';
import { respondTo } from '../../../../utils/breakpoints';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export default function Hero() {
  const { t } = useTranslation('ami');
  return (
    <>
      <HeroSection>
        <AmiSection id="hero">
          <SectionWrapper>
            <AmiHeroContainer>
              <AmiHeroContentWrapper>
                <AmiContainer>
                  <AMILogo src={AmiLogo} alt="Akademia Młodego Innowatora" />
                  <AmiHeroContentWrapper>
                    <OverTitle>{t('heroSection.overtitle')}</OverTitle>
                    <HeroTitle>{t('heroSection.title')}</HeroTitle>
                    <Lead>{t('heroSection.lead')}</Lead>
                  </AmiHeroContentWrapper>
                </AmiContainer>
              </AmiHeroContentWrapper>
            </AmiHeroContainer>
          </SectionWrapper>
          <InnovatorGirls className="mobile" src={GirlsInnovatorImage} alt="" />
        </AmiSection>
        <SectionWrapper>
          <Column className="gap-0">
            <Text className="purple">{t('heroSection.inCollaboration')}</Text>
            <Row className="mobile-wrap">
              <SponsorImage src={AstraZenecaLogo} alt="AstraZeneca" />
              <SponsorImage src={ForumLogo} alt="Logo Forum" />
              <InnovatorGirls src={GirlsInnovatorImage} alt="" />
            </Row>
          </Column>
        </SectionWrapper>
      </HeroSection>
    </>
  );
}

const InnovatorGirls = styled.img`
  width: 100%;
  display: none;
  ${respondTo.m`
    height: 501px;
    width: auto;
    position: absolute;
    display: block;
    right: 0;
    bottom: 0;
  `}
  &.mobile {
    display: block;
    ${respondTo.m`
      display: none;
    `}
  }
`;

const SponsorImage = styled.img`
  width: 40%;
  ${respondTo.m`
    height: 90px;
    width: auto;
  `}
`;

const AMILogo = styled.img`
  height: 40px;
  width: calc(320 / 60 * 40px);
  ${respondTo.m`
    width: 320px;
    height: 60px;
  `}
`;
