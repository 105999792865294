import styled from 'styled-components';

export const LawyersContainer = styled.div`
  background: var(--UI-Blue-Tint, #f5fafd);

  .container {
    display: flex;
    padding: 60px 0px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  h2 {
    max-width: 690px;
    text-align: center;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 60px 20px;
  flex-wrap: wrap;
`;

export const PersonContainer = styled.div`
  display: flex;
  width: 180px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
`;
export const PersonImage = styled.div`
  width: 130px;
  height: 130px;
  flex-shrink: 0;

  border-radius: 200px;
  border: 7px solid var(--Mid-blue, #2b95b7);
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-size: cover !important;
`;

export const PersonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
