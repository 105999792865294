import React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const HeroSection = () => {
  const {t} = useTranslation();
  return (
    <div>
      <section className="page-section">
        <div className='team-header-y-padding'>
          <div className='team-header-ctn'>
            <section className="container">
            <div>
              <StaticImage
                className="team-header-bg-img"
                src="../../../images/team/header-background-mask.png"
                alt="Theme circles"
                placeholder="none"
                loading="lazy"
              />
            </div>
            <p>{t`team-title-1`}</p>
            <h1>{t`team-title-2`}</h1>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;