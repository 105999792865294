import { Column, Icon, Row, Tag } from '../../../../components/global/utils';
import styled, { css } from 'styled-components';

import { respondTo } from '../../../../utils/breakpoints';

export const ClockIcon = styled(Icon)`
  margin: 2px 8px 0 0;
  width: 16px;
  height: 16px;
`;
export const RoundedBox = styled.div`
  border: 1px solid #4ebfee;
`;

export const coverCss = css`
  .cover {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    aspect-ratio: 1/1;
    height: 3rem;
    border-radius: 50%;
    i {
      margin: 0;
    }
  }

`;

export const Bar = styled(Row)`
  height: 5rem;
  max-width: 1180px;
  box-shadow: 0px 0px 20px rgba(15, 83, 115, 0.2);
  border-radius: 10px;
  margin-bottom: 1rem;
  background: white;
  color: #0f5575;
  font-family: 'Montserrat';
  font-size: 24px;
  line-height: 29px;
  justify-content: space-between;

  &.event-page {
    min-height: 5rem;
    height: auto !important;
    max-height: auto;
  }

  ${coverCss}
`;

export const Hour = styled.div`
  font-family: 'Montserrat';
  color: #4ebfee;
  background: #f5fafd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  box-sizing: border-box;
  height: 100% !important;
  min-height: 80px;
  max-height: 200px;
  padding: 24px 10px;
  min-width: 100px;
  font-weight: 600;
  line-height: 29px;
  border-radius: 0px 0px 0px 8px;
  position: absolute; 
  left: 0;
  top: 0;
  ${respondTo.m`
    border-radius: 0px;
    position: relative;
    margin-left: 0;
    margin-top: 0;
  `}
`;

export const Person = styled(Row)`
  color: #5fa5c5;
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  img {
    margin-right: 1rem;
    object-fit: cover;
  }
`;

export const StyledTag = styled(Tag)`
  margin: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 32px;
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 14px;
  padding: 4px 16px;
  ${respondTo.m`
    margin: 0;
    margin-right: 1rem;
    margin-left: 1.5rem;
  `}
`;

export const AgendaTitle = styled.span`
  max-width: 695px;
  font-size: 16px;
  margin: 12px 0;
  padding: 16px;
  font-weight: 600;
  &.event {
    margin: 0px;
    color: var(--ui-dark-blue, #0F5575);
    /* Body/M Regular */
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    padding: 16px 16px 0px 16px;
  }
  &.speaker {
    margin: 0px 0px;
    color: var(--ui-blue, #5FA5C5);
    /* Body/S Regular */
    font-family: 'Raleway';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    width: 100%;
    max-width: 220px !important;
    padding: 16px;
    ${respondTo.m`
      width: 100%;
      padding: 0px 16px 16px 16px;
      max-width: 500px !important;  
    `}
  }
`;

export const MobileColumn = styled(Column)`
  box-shadow: 0px 0px 20px rgba(15, 83, 115, 0.2);
  border-radius: 10px;
  margin-bottom: 1.5rem;
  background: white;
`;

export const MobileRow = styled(Row)`
  min-height: 80px;
  box-sizing: border-box;
  height: fit-content;
  color: #0f5575;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Montserrat';
  font-size: 18px;
  ${coverCss}
`;

export const MobileAgendaTitle = styled.span`
  line-height: 150%;
  color: #0f5575;
  font-weight: 600;
`;

export const MobileAgendaBox = styled.div`
  box-sizing: border-box;
  margin-left: 100px;
  padding: 24px 10px;
  height: fit-content;
`;

export const Label = styled.span`
  color: var(--ui-light-blue, #4EBFEE);
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  max-width: 80px !important;
  text-align: center;
  box-sizing: border-box;
  justify-content: center;
  border-radius: 8px;
  background: var(--ui-blue-tint, #F5FAFD);
  align-self: flex-end;
  margin-right: 8px;
  ${respondTo.m`
    align-self: center;
    max-width: 100px !important;
    padding: 4px 16px;
    margin-right: 0;
  `}
`
