import styled from 'styled-components';

export const FirstTutorialContainer = styled.div`
  background: var(--UI-Dark-Blue, #0f5575);
  display: flex;
  width: 100%;

  margin-left: 0;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const RelativeContainer = styled.div`
  display: inline-flex;
  position: relative;
  height: 100%;
  margin-left: 20px;
  margin-top: auto;
  /* width: 50%; */
  max-width: 492px;

  .jakub-image {
    z-index: 2;
  }
  .circles-up {
    position: absolute !important;
    top: 30px;
    left: 0px;
    z-index: 1;
    img {
      width: 217px !important;
      height: 200px !important;
    }
  }

  .circles-down {
    position: absolute !important;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    img {
      width: 151px !important;
      height: 317px !important;
    }
  }
  @media only screen and (max-width: 1000px) {
    margin: auto;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  gap: 50px;
  width: 65%;
  margin-right: 24px;
  box-sizing: border-box;

  .blue-header {
    color: var(--UI-Light-Blue, #4ebfee);
  }

  h2 {
    max-width: 780px;
  }

  @media only screen and (max-width: 1000px) {
    width: 80%;
    box-sizing: border-box;
    margin: 0 24px;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding-left: 60px;
  box-sizing: border-box;

  @media only screen and (max-width: 1000px) {
    padding: 0px;
  }
`;

export const BottomTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`;

export const DescriptionText = styled.p`
  color: #fff;
  font-family: Raleway;
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: 170%; /* 30.6px */
  letter-spacing: -0.36px;
`;

export const SignatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const NameText = styled.p`
  color: var(--White, rgba(255, 255, 255, 0.9));
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
`;
export const PositionText = styled.p`
  color: var(--UI-Light-Blue, #4ebfee);
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;
