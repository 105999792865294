import React from 'react';
import Layout from '../../components/layout/layout';
import ogImage from '../../images/seo/SEO_fundacja.jpg';
import Seo from '../../components/seo';
import '../team/team-style.css';
import {I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next'
import getCurrentTranslations from '../../components/contentful-translator';
import HeroSection from './components/hero-section';
import TeamSlider from './components/slider/team-slider';
import FoundationBoardSection from './components/foundation-board-section';
import FoundationManagementSection from './components/foundation-managment-section';
import TeamInformationSection from './components/information-section';
import MirtWorkingGroupSection from './components/mirit-working-group-section';


const TeamPage = ({data}) => {

  const {t} = useTranslation();
  const [councilor, setCouncilor] = React.useState([])
  const [management, setManagement] = React.useState([])
  const [director, setDirector] = React.useState([])
  const [groupMember, setGroupMember] = React.useState([])

  const [members, setMembers] = React.useState([])

  const {language} = React.useContext(I18nextContext);

  React.useEffect(() => {

    const properData = getCurrentTranslations(data.teamMembers.edges, language)
    let teams = [];
    for (let member of properData) {

      let isDepartmentAlreadyIn = false;
      for (let team of teams) {
        if (member.node.department === team.department){
          team.members.unshift(member.node);
          isDepartmentAlreadyIn = true;
        }
      }
      if (isDepartmentAlreadyIn === false)
        teams.unshift({
          department: member.node.department,
          members: [member.node]
        })
    }
    const FOUNDATION_ADVISORY_BOARD_NAME = 'Rada doradcza przy Fundacji';
    const MANAGEMENT_NAME = 'Zarząd';
    const DIRECTORS_NAME = 'Dyrektorzy';
    const MRIT_WORKING_GROUP_NAME = 'Grupa Robocza MRiT'
    const CORE_TEAM = 'Core team';

    setCouncilor(teams.filter(team => team.department === FOUNDATION_ADVISORY_BOARD_NAME)[0]);
    setManagement(teams.filter(team => team.department === MANAGEMENT_NAME)[0]);
    setDirector(teams.filter(team => team.department === DIRECTORS_NAME)[0]);
    setGroupMember(teams.filter(team => team.department === MRIT_WORKING_GROUP_NAME)[0]);
    const members = teams
      .filter(team => team.department === CORE_TEAM)
      .map(team => team.members)
      .flat()
      .sort((t1, t2) => t1.name.localeCompare(t2.name))
    setMembers(members)
  }, [data, language])

  return (
    <Layout withHeader={false}>
      <Seo
        title={t('team.page-title')}
        description={t('team.page-description')}
        ogImage={ogImage}
      />
      <div className='team-page'>
        <HeroSection/>
        <TeamInformationSection question={t`advisory-board-at-the-foundation`} text={t`advisory-board-foundation-text`} isFoundationAdvisoryBoard={true}/>
        <TeamSlider management={councilor} t={t} />
        <FoundationBoardSection management={management} t={t} />
        <FoundationManagementSection management={director} t={t}/>
        <TeamSlider management={groupMember} t={t} isSecondary={true}/>
        <MirtWorkingGroupSection/>
      </div>
    </Layout>
  );
}

export default TeamPage;
