import { Column, Icon } from '../../../../components/global/utils';
import {
  LinkedinRow,
  SpeakerDescription,
  SpeakerImg,
  SpeakerTitle,
  SpeakerTitleBold
} from './styled.components';

import React from 'react';

export const Speaker = (props) => {
  return (
    <Column style={{ marginBottom: '1rem' }} className="align-center gap-4">
      <SpeakerImg
        className={props?.small ? 'small m-1' : ''}
        alt={[props.name]}
        src={props.photo.file.url}
      />
      <SpeakerTitle className={props?.small ? 'small margin-0' : ''}>
        {props.name}
      </SpeakerTitle>
      {!props.noDescription ? (
        <SpeakerDescription className="margin-0">
          {props.description.description}
        </SpeakerDescription>
      ) : null}
      {props.title ? (
        <SpeakerTitleBold className="margin-0">{props.title}</SpeakerTitleBold>
      ) : null}
      {props.linkedin && (
        <LinkedinRow className="align-center">
          <Icon name="outlined-linkedin"></Icon>
          <a href={props.linkedin}>
            {props?.linkedin
              ?.replace('www.', '')
              .replace('https://', '')
              .replace('linkedin.com/in/', '')
              .replace('/', '')}
          </a>
        </LinkedinRow>
      )}
    </Column>
  );
};
