import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  WhyContainer,
  WhyLeftContainer,
  WhyRightContainer
} from './styled.components';
import { H2Style, ParagraphMediumStyle } from '../../styled.components';
import { StaticImage } from 'gatsby-plugin-image';
import '../../../../images/documents-page/why/documents-why-image.webp';
import { Trans } from 'react-i18next';
const WhySection = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="container">
        <WhyContainer>
          <WhyLeftContainer>
            <H2Style color="#080E14">{t`documents-page.why-title`}</H2Style>
            <StaticImage
              className="why-image"
              src="../../../../images/documents-page/why/documents-why-image.webp"
              alt="Why image"
              placeholder="none"
              loading="lazy"
            />
          </WhyLeftContainer>
          <WhyRightContainer>
            <ParagraphMediumStyle>{t`documents-page.why-description-1`}</ParagraphMediumStyle>
            <ParagraphMediumStyle>
              <Trans
                i18nKey="documents-page.why-description-2"
                components={{
                  a: <a href="#tutorial"></a>
                }}
              />
            </ParagraphMediumStyle>
            <ParagraphMediumStyle>
              <Trans
                i18nKey="documents-page.why-description-3"
                components={{
                  a: <a href="#downloads"></a>
                }}
              />
            </ParagraphMediumStyle>
            <ParagraphMediumStyle>{t`documents-page.why-description-4`}</ParagraphMediumStyle>
            <ParagraphMediumStyle>{t`documents-page.why-description-5`}</ParagraphMediumStyle>
          </WhyRightContainer>
        </WhyContainer>
      </div>
    </>
  );
};

export default WhySection;
