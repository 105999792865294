import {
  ArticleContent,
  ArticleDetails,
  ArticleHeader,
  ArticleHeaders,
  ArticleTimeIcon,
  ArticleTitle,
  ArticleType,
  ArticlesWrapper,
  AuthorBox,
  AuthorImg,
  ReadingTime
} from './styled.components';
import { Column, Row } from '../../components/global/utils';
import { FacebookShareButton, LinkedinShareButton } from 'react-share';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useContext } from 'react';
import {
  faFacebookSquare,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons';

import Breadcrumbs from '../../components/global/breadcrumbs';
import Layout from '../../components/layout/layout';
import MentoringModal from '../../components/modals/mentoring/MentoringModal';
import { Page } from '../../components/global/page';
import { PageWrapper } from '../../views/news/styled.components';
import { RecommendedElements } from '../components/recommended-elements';
import { SectionWrapper } from '../../components/global/flexbox';
import Seo from '../../components/seo';
import { ThemeProvider } from 'styled-components';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import getCurrentTranslations from '../../components/contentful-translator';
import { graphql } from 'gatsby';
import moment from 'moment';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { richTextRenderOptions } from '../../utils/templateRenderOptions';
import { theme } from '../../themes/default';

export default function ArticlePage({ data, pageContext }) {
  const [lastThreeNews, setLastThreeNews] = React.useState();

  const sortedArray = (array) => {
    return array.sort((a, b) => {
      const dateA = new Date(a.node.createdAt);
      const dateB = new Date(b.node.createdAt);
      return dateB - dateA;
    });
  };
  const { language } = useContext(I18nextContext);

  React.useEffect(() => {
    const getLastThreeNews = () => {
      const news = getCurrentTranslations(
        data.allContentfulNews.edges,
        language
      );
      if (news) {
        const newsWithoutDuplicates = news.filter(
          (item) => item.node.title !== pageContext.title
        );
        const sortedArticles = sortedArray(newsWithoutDuplicates);
        setLastThreeNews(sortedArticles.slice(0, 3));
      }
    };
    getLastThreeNews();
  }, [pageContext, data.allContentfulNews, language]);

  const { t } = useTranslation();
  const breadcrumbsPath = [
    { name: t('breadcrumbs.news'), path: '/news/' },
    { name: t('breadcrumbs.news'), path: '/news/' + pageContext.contentful_id }
  ];
  return (
    <Layout withHeader={false}>
      <Seo
        title={pageContext.title + '| Innovations Academy'}
        description={t`pageContext.seo.description`}
        ogImage={pageContext?.thumbnail?.file?.url}
      />
      <Page className="articles" style={{ marginLeft: 0, marginRight: 0 }}>
        <ThemeProvider theme={theme}>
          <PageWrapper>
            <ArticlesWrapper className="border-bottom">
              <ArticleHeaders>
                <ArticleHeader>
                  <Breadcrumbs path={breadcrumbsPath} />
                  <ArticleType>{pageContext.altTItle}</ArticleType>
                  <ArticleTitle>{pageContext.title}</ArticleTitle>
                </ArticleHeader>
                <ArticleDetails shouldWrap={true}>
                  {pageContext?.authors?.map((item) => {
                    return (
                      <AuthorBox>
                        <AuthorImg src={item?.file?.url}></AuthorImg>
                        <Column>
                          <strong>{item?.title}</strong>
                          <ReadingTime>{item?.description}</ReadingTime>
                        </Column>
                      </AuthorBox>
                    );
                  })}
                  <AuthorBox className="no-border">
                    <ArticleTimeIcon className="circle" icon={faClock} />
                    <Column>
                      <div>
                        {moment(pageContext?.createdAt).format('DD.MM.YYYY')}
                      </div>
                      <ReadingTime>{String('2 ' + t('read_time'))}</ReadingTime>
                    </Column>
                  </AuthorBox>
                  <AuthorBox className="no-border end">
                    <Column>
                      <span>{t`template-article-share`}</span>
                      <Row>
                        <FacebookShareButton
                          url={String(
                            'innovationshub.pl/news/articles/' +
                              pageContext.slug
                          )}
                          quote={String(pageContext.title)}>
                          <ArticleTimeIcon icon={faFacebookSquare} />
                        </FacebookShareButton>
                        <LinkedinShareButton
                          url={String(
                            'innovationshub.pl/news/articles/' +
                              pageContext.slug
                          )}>
                          <ArticleTimeIcon icon={faLinkedinIn} />
                        </LinkedinShareButton>
                      </Row>
                    </Column>
                  </AuthorBox>
                </ArticleDetails>
              </ArticleHeaders>
            </ArticlesWrapper>
            <ArticlesWrapper>
              <SectionWrapper>
                <img src={pageContext.thumbnail?.url} />
                <ArticleContent>
                  <img src={pageContext.thumbnail.file.url} alt="main"></img>
                  {renderRichText(pageContext?.text, richTextRenderOptions)}
                </ArticleContent>
              </SectionWrapper>
            </ArticlesWrapper>
            <RecommendedElements elements={lastThreeNews} type={'news'} />
          </PageWrapper>
        </ThemeProvider>
      </Page>
      <MentoringModal />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulNews {
      edges {
        node {
          title
          contentful_id
          node_locale
          thumbnail {
            file {
              url
            }
          }
          authors {
            title
          }
          createdAt
        }
      }
    }
  }
`;
