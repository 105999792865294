import {
  GridContent,
  GridImg,
  GridItem,
  GridRow,
  NewsBox
} from '../../../views/news/components/highlight/styled.components';
import { SectionHeader, SubHeader } from '../../../components/typography';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import { Dot } from '../../../components/global/utils';
import React from 'react';
import { SectionHeaders } from '../../../views/news/styled.components';
import { SectionWrapper } from '../../../components/global/flexbox';
import moment from 'moment';

export function RecommendedElements({ elements, type }) {
  const { t } = useTranslation();

  const returnDate = (item) => {
    switch (type) {
      case 'news':
        return item?.node?.createdAt;
      case 'articles':
        return item?.node?.publishDate;
      case 'workshops':
        return item?.node?.data;
    }
  };

  const returnAuthor = (item) => {
    switch (type) {
      case 'news':
        return item?.node?.authors[0]?.title;
      case 'articles':
        return item?.node?.authors[0]?.title;
      case 'workshops':
        return item?.node?.speakers[0]?.name;
    }
  };

  const returnHeader = (type) => {
    switch (type) {
      case 'news':
        return t('news.recommended-news');
      case 'articles':
        return t('news.article.recommended-articles');
      case 'workshops':
        return t('news.workshops.recommended-workshops');
    }
  };

  return (
    <>
      <SectionWrapper style={{ width: 'auto' }}>
        <SectionHeaders>
          <SectionHeader>
            <Trans>{returnHeader(type)}</Trans>
          </SectionHeader>
        </SectionHeaders>
        <NewsBox className={'medtech'}>
          {elements &&
            elements.map((item) => (
              <GridItem
                key={t(item.node.title)}
                to={
                  type === 'news'
                    ? `/news/${item.node.contentful_id}`
                    : `/news/${type}/${item.node.slug}`
                }>
                <GridImg bgImg={item.node.thumbnail.file.url} />
                <GridContent>
                  <SubHeader>
                    {item.node.title.substring(0, 60) + '...'}
                  </SubHeader>
                  <GridRow>
                    <p>{returnAuthor(item)}</p>
                    <Dot className="article" />
                    <p>{moment(returnDate(item)).format('DD MMM YYYY')}</p>
                  </GridRow>
                </GridContent>
              </GridItem>
            ))}
        </NewsBox>
      </SectionWrapper>
    </>
  );
}
