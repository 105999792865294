import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  HeroContainer,
  HeroDescription,
  HeroLeftContainer,
  HeroTextContainer
} from './styled.components';
import { H1Style, H3Style, WhiteButton } from '../../styled.components';

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeroContainer>
        <div className="container">
          <HeroLeftContainer>
            <HeroTextContainer>
              <H3Style color="#4EBFEE">{t`documents-page.hero-sub-title`}</H3Style>
              <H1Style>{t`documents-page.hero-title`}</H1Style>
              <HeroDescription>{t`documents-page.hero-description`}</HeroDescription>
            </HeroTextContainer>
            <WhiteButton
              as="a"
              href="#documents">{t`documents-page.hero-button`}</WhiteButton>
          </HeroLeftContainer>
        </div>
      </HeroContainer>
    </>
  );
};

export default HeroSection;
