import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const TeamInformationSection = ({ question, text, isFoundationAdvisoryBoard }) => {


  const TeamImage = () => (
    <div className="team-photo-section">
      <div className="team-photo-wrapper">
        <div className="blue-circle"></div>
        <StaticImage
          className="team-photo"
          src="../../../images/team/inhub-team.jpeg"
          alt="Our amazing team"
        />
      </div>
    </div>
);
  const FoundationAdvisoryBoardImage = () => (
    <div className="team-photo-section">
     <div className="team-photo-wrapper">
        <div className="light-blue-circle"></div>
        <StaticImage
          className="team-photo"
          src="../../../images/team/advisory-board-photo.jpg"
          alt="Council photo"
        />
      </div>
    </div>
  );

  return (
    <section className="container">
      <div className="team-mid-section-ctn ">
        <div className="team-mid-section-informations">
          <h2>{question}</h2>
          <p>{text}</p>
        </div>
        {isFoundationAdvisoryBoard ? <FoundationAdvisoryBoardImage /> : <TeamImage />}
      </div>
    </section>
  );
};

export default TeamInformationSection;
