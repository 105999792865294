// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-akademia-mlodego-innowatora-js": () => import("./../../../src/pages/akademia-mlodego-innowatora.js" /* webpackChunkName: "component---src-pages-akademia-mlodego-innowatora-js" */),
  "component---src-pages-bootcamp-js": () => import("./../../../src/pages/bootcamp.js" /* webpackChunkName: "component---src-pages-bootcamp-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-ens-js": () => import("./../../../src/pages/ens.js" /* webpackChunkName: "component---src-pages-ens-js" */),
  "component---src-pages-fsts-js": () => import("./../../../src/pages/fsts.js" /* webpackChunkName: "component---src-pages-fsts-js" */),
  "component---src-pages-future-academy-js": () => import("./../../../src/pages/future-academy.js" /* webpackChunkName: "component---src-pages-future-academy-js" */),
  "component---src-pages-green-innovations-challenge-js": () => import("./../../../src/pages/green-innovations-challenge.js" /* webpackChunkName: "component---src-pages-green-innovations-challenge-js" */),
  "component---src-pages-incubator-js": () => import("./../../../src/pages/incubator.js" /* webpackChunkName: "component---src-pages-incubator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentoring-new-js": () => import("./../../../src/pages/mentoring-new.js" /* webpackChunkName: "component---src-pages-mentoring-new-js" */),
  "component---src-pages-mentoring-programme-registration-form-2024-js": () => import("./../../../src/pages/mentoring-programme-registration-form-2024.js" /* webpackChunkName: "component---src-pages-mentoring-programme-registration-form-2024-js" */),
  "component---src-pages-mentors-js": () => import("./../../../src/pages/mentors.js" /* webpackChunkName: "component---src-pages-mentors-js" */),
  "component---src-pages-mpk-js": () => import("./../../../src/pages/mpk.js" /* webpackChunkName: "component---src-pages-mpk-js" */),
  "component---src-pages-news-articles-js": () => import("./../../../src/pages/news/articles.js" /* webpackChunkName: "component---src-pages-news-articles-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-interviews-js": () => import("./../../../src/pages/news/interviews.js" /* webpackChunkName: "component---src-pages-news-interviews-js" */),
  "component---src-pages-news-workshops-js": () => import("./../../../src/pages/news/workshops.js" /* webpackChunkName: "component---src-pages-news-workshops-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-previous-editions-js": () => import("./../../../src/pages/previous-editions.js" /* webpackChunkName: "component---src-pages-previous-editions-js" */),
  "component---src-pages-program-inkubacyjny-js": () => import("./../../../src/pages/program-inkubacyjny.js" /* webpackChunkName: "component---src-pages-program-inkubacyjny-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-registration-suplemmentary-js": () => import("./../../../src/pages/registration-suplemmentary.js" /* webpackChunkName: "component---src-pages-registration-suplemmentary-js" */),
  "component---src-pages-regulations-js": () => import("./../../../src/pages/regulations.js" /* webpackChunkName: "component---src-pages-regulations-js" */),
  "component---src-pages-rodo-js": () => import("./../../../src/pages/rodo.js" /* webpackChunkName: "component---src-pages-rodo-js" */),
  "component---src-pages-statute-js": () => import("./../../../src/pages/statute.js" /* webpackChunkName: "component---src-pages-statute-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-under-construction-js": () => import("./../../../src/pages/under-construction.js" /* webpackChunkName: "component---src-pages-under-construction-js" */),
  "component---src-templates-articles-index-jsx": () => import("./../../../src/templates/articles/index.jsx" /* webpackChunkName: "component---src-templates-articles-index-jsx" */),
  "component---src-templates-interview-page-js": () => import("./../../../src/templates/interviewPage.js" /* webpackChunkName: "component---src-templates-interview-page-js" */),
  "component---src-templates-news-index-jsx": () => import("./../../../src/templates/news/index.jsx" /* webpackChunkName: "component---src-templates-news-index-jsx" */),
  "component---src-templates-regulations-js": () => import("./../../../src/templates/regulations.js" /* webpackChunkName: "component---src-templates-regulations-js" */),
  "component---src-templates-workshops-index-jsx": () => import("./../../../src/templates/workshops/index.jsx" /* webpackChunkName: "component---src-templates-workshops-index-jsx" */)
}

