import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "../slider/team-slider-style.css";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import MailIcon from './svg/mail-icon';
import LinkedinIcon from './svg/linkedinn-icon';

function SampleNextArrow(props) {
  const { className, onClick, isSecondary } = props;
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19 12H5"
        stroke={isSecondary ? "white" : "#0F5575"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 18L19 12"
        stroke={isSecondary ? "white" : "#0F5575"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 6L19 12"
        stroke={isSecondary ? "white" : "#0F5575"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick, isSecondary } = props;
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5 12H19"
        stroke={isSecondary ? "white" : "#0F5575"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 6L5 12"
        stroke={isSecondary ? "white" : "#0F5575"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 18L5 12"
        stroke={isSecondary ? "white" : "#0F5575"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const TeamSlider = ({ management, isSecondary }) => {
  const sliderRef = useRef();
  const {t} = useTranslation();
  const totalSlides = isSecondary ? 20 : 15;
  const slidesToShow = 6;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    arrows: true,
    nextArrow: <SampleNextArrow isSecondary={isSecondary} />,
    prevArrow: <SamplePrevArrow isSecondary={isSecondary} />,
    beforeChange: (currentSlide, nextSlide) => {
      setCurrentPage(Math.ceil(nextSlide / slidesToShow) + 1);
    },
    responsive: [
      {
        breakpoint: 1355,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const totalPages = Math.ceil(totalSlides / slidesToShow);
  const [currentPage, setCurrentPage] = React.useState(1);
  return (
    <div>
      <div className={`team-slider-wrapper ${isSecondary ? 'secondary' : ''}`}>
        <section className="container">
          <div className={`team-slider-title ${isSecondary ? 'secondary' : ''}`}>
            <div className={`team-slider-title-h2-wrapper ${isSecondary ? 'secondary' : ''}`}>
            <h2>{t`working-group-at-mdat`}</h2>
              <div className="team-slider-title-h2-group-wrapper">
                <div className="working-group-at-mdat2">
                  <h2>{t`working-group-at-mdat2`}</h2>
                  <h2>{t`working-group-at-mdat2.1`}</h2>
                </div>
                <div className="team-slider-blue-h2-wrapper">
                  <div className="team-slider-blue-h2-3">
                    <h2 className="team-slider-blue-h2">{t`working-group-at-mdat3.0`}</h2>
                    <h2 className="team-slider-blue-h2">{t`working-group-at-mdat3`}</h2>
                  </div>
                  <div className="team-slider-blue-h2-3-1">
                    <h2 className="team-slider-blue-h2">{t`working-group-at-mdat3.1`}</h2>
                    <h2 className="team-slider-blue-h2">{t`working-group-at-mdat3.1.1`}</h2>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="container">{isSecondary ? t`group-composition` : t`council-composition`}</h3>
          </div>
        </section>
        <div className={`team-slider-section ${isSecondary ? 'secondary' : ''} `}>
            <Slider {...settings} ref={sliderRef} className="team-slider container">
              {management?.members?.map((member, index) => (
                <section key={member.name} className={`team-member-slider ${isSecondary ? 'secondary' : ''}`}>
                  <img
                    src={member.photo?.file.url}
                    alt={`${member.photo?.title} - profile`}
                  />
                  <h3 style={{ color: isSecondary ? 'white' : '#0F5575' }}>{member.name}</h3>
                  <h4 style={{ color: isSecondary ? 'white' : '#0F5575' }}>{member.role}</h4>
                  <div className="slider-links-box">
                    {member?.email && (
                      <a className="contact-info">
                        <MailIcon isIconBrightBlue={isSecondary}/>
                        <p>{member?.email}</p>
                      </a>
                    )}
                    {member?.linkedin && (
                      <a
                        className="contact-info"
                        href={member?.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkedinIcon isIconBrightBlue={isSecondary}/>
                        <p className="linkedin-link-wrapper">{member?.linkedin.substring(25)}</p>
                      </a>
                    )}
                  </div>
                </section>
              ))}
            </Slider>
            <div className={`slider-pagination ${isSecondary ? 'secondary' : ''}`}>
              {currentPage}/{totalPages}
            </div>
          </div>
      </div>
</div>
)
  ;
};

export default TeamSlider;
