
import React from "react";

const LinkedinIcon = ({ isIconBrightBlue = false }) => {

  const strokeColor = isIconBrightBlue ? "#4EBFEE" : "#0F5575";

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2581_1416)">
        <path
          d="M16.1042 3.33301H5.89658C4.95699 3.33301 4.19531 4.0792 4.19531 4.99967V14.9997C4.19531 15.9201 4.95699 16.6663 5.89658 16.6663H16.1042C17.0437 16.6663 17.8054 15.9201 17.8054 14.9997V4.99967C17.8054 4.0792 17.0437 3.33301 16.1042 3.33301Z"
          stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.59375 9.16699V13.3337" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
        <path d="M7.59375 6.66699V6.67533" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
        <path d="M11 13.3337V9.16699" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round"
              strokeLinejoin="round" />
        <path
          d="M14.4025 13.3337V10.8337C14.4025 10.3916 14.2233 9.96771 13.9042 9.65515C13.5852 9.34259 13.1525 9.16699 12.7013 9.16699C12.2501 9.16699 11.8173 9.34259 11.4983 9.65515C11.1792 9.96771 11 10.3916 11 10.8337"
          stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2581_1416">
          <rect width="20.4152" height="20" fill="white" transform="translate(0.789062)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedinIcon;
