import { SectionHeader } from '../typography';
import { respondTo } from '../../utils/breakpoints';
import styled from 'styled-components';

export const PageWrapper = styled.div`
  font-family: 'Raleway';
  margin-top: ${(props) => (!props.withHeader ? '112px' : '0') /*navigation height*/
  };
`;

export const SectionWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
  ${respondTo.xs`  
    width: 100%;
    padding: 0 1.5rem;
  `}
  ${respondTo.s`
    padding: 0 5%;
    max-width: 90%;
  `}
  ${respondTo.m`
    margin: auto;
    padding: 0 0;
    max-width: 1064px;
  `}
  ${respondTo.l`
    margin: auto;
    padding: 0 0;

    max-width: 1180px;
  `}
  ${respondTo.xl`
    margin: auto;
    padding: 0 0;
    max-width: 1180px;
  `}
`;

export const PageSection = styled.section`
  padding: 3rem 0 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: ${(props) => props.flex_align || 'center'};
  background-image: ${(props) => `url(${props.bg_img})` || 'none'};
  background-repeat: no-repeat;
  background-position: ${(props) => props.bg_img_pos_mobile || 'bottom center'};
  background-size: ${(props) => props.bg_img_size_mobile || ""};
  position: relative;
  background-color: ${(props) => props.bg_col || ''};
  ${respondTo.m`
    background-position: ${(props) => props.bg_img_pos || 'bottom center'};
    background-size: ${(props) => props.bg_img_size || 'default'};
  `}
  &.hero {
    > ${SectionWrapper} {
      z-index: 3;
    }
    min-height: 500px;
    >
    ${respondTo.s`
      height: 600px;
    `}
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: black;
      opacity: 0.5;
    }
  }
  > * h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    width: 100%;
    text-align: ${(props) => props.align_text || 'left'};
    ${respondTo.m`
      text-align: center;
    `}
  }
  &.two-tone {
    height: auto;
    padding-bottom: 0;
    margin-top: 7.5rem;
    &.Okun {
      margin-top: 0rem;
    }
    background: linear-gradient(#ffffff 50%, #0f5575 50%) !important;
    ${respondTo.m`
      height: 499px;
      background: linear-gradient(90deg, #ffffff 50%, #0f5575 50%) !important;
    `}
  }
  &.invert {
    padding-top: 4rem;
    max-width: 100vw;
    height: auto;
    background-color: rgb(15, 85, 117);
    color: white;
    margin-top: 3.5rem !important;
    &:nth-of-type(1) {
      margin-top: 0;
    }
    &.pb {
      padding-bottom: 4rem !important;
    }
    ${respondTo.m`
      margin-top: 0;
      padding: 3rem 0 0 0 !important;
    `}
    &:first-of-type {
      > * h1 {
        font-size: 48px;
        font-weight: 700;
        letter-spacing: -0.02rem;
        line-height: 59px;
        color: white;
        margin-bottom: 1rem;
      }
      > * p {
        text-align: left;
        color: white;
        font-size: 32px;
        line-height: 150%;
        letter-spacing: -0.02rem;
        font-weight: 400;
      }
    }
    > * ${SectionHeader} {
      color: white !important;
    }
    > * p {
      text-align: center;
      color: white;
      padding-bottom: 2rem;
    }
  }
`;

export const FlexWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.flex_items || 'center'};
  flex-direction: ${(props) => props.flex_direction || 'row'};
  align-items: ${(props) => props.flex_align || 'center'};
`;

export const FlexBox = styled(SectionWrapper)`
  height: 100%;
  width: ${(props) => props.flex_width || '100%'};
  display: flex;
  justify-content: ${(props) => props.flex_items || 'center'};
  flex-direction: ${(props) => props.flex_direction || 'row'};
  align-items: ${(props) => props.flex_align || 'center'};
  gap: ${(props) => props.gap || 'inherit'};
  position: relative;
  > ${SectionWrapper} {
    margin: 0;
  }
  .mpk-video {
    ${respondTo.xs`
      width: 90%;
    `}
  }
  &.hero {
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.2;
    }
  }
  > .flex-child {
    height: 100%;
    width: 50%;
    display: ${(props) => props.flex || 'block'};
    justify-content: ${(props) => props.flex_justify || 'center'};
    align-items: ${(props) => props.flex_align || 'center'};
    &.thirty {
      height: 100%;
      width: 30%;
    }
    &.four-six {
      &:first-of-type {
        width: 45%;
        padding-right: 0rem;
      }
      &:nth-of-type(2) {
        width: 55%;
      }
    }
    p {
      padding-bottom: 2rem;
    }
    &.blue {
      color: white;
      > * {
        color: white;
      }
      > h2 {
        color: white !important;
      }
    }
    &:first-of-type {
      padding-right: 8rem;
    }
    > h2 {
      text-align: left !important;
    }
  }
  @media only screen and (max-width: 1224px) {
    flex-direction: column;

    > .flex-child {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &.thirty {
        width: 100%;
      }
      &.four-six {
        width: 100% !important;
        &:first-of-type {
          padding-bottom: 0rem;
        }
        &:nth-of-type(2) {
          padding-bottom: 8rem;
        }
      }
      &:first-of-type {
        padding-right: 0rem;
        padding-bottom: 8rem;
      }
    }
  }
`;
