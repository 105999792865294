import styled from 'styled-components';

export const H1Style = styled.h1`
  color: ${(props) => props.color || '#FFF'};
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  margin: 0;
`;

export const H2Style = styled.h2`
  color: ${(props) => props.color || '#FFF'};
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.72px;
  margin: 0;
`;

export const H3Style = styled.h3`
  color: ${(props) => props.color || '#FFF'};
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
`;

export const H4Style = styled.h3`
  color: ${(props) => props.color || '#FFF'};
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.36px;
  margin: 0;
`;

export const ParagraphMediumStyle = styled.p`
  color: ${(props) => props.color || '#080E14'};
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
`;

export const ParagraphSmallStyle = styled.p`
  color: ${(props) => props.color || '#080E14'};
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const WhiteButton = styled.button`
  display: inline-flex;
  height: 55px;
  box-sizing: border-box;
  padding: 8px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--UI-Blue-Tint, #f5fafd);
  border: 2px solid var(--UI-Blue-Tint, #f5fafd);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  color: var(--UI-Dark-Blue, #0f5575);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.32px;

  text-decoration: none;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid var(--UI-Blue-Tint, #f5fafd);

    color: var(--UI-Blue-Tint, #f5fafd);
  }
`;
