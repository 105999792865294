import {
  MentoringModalButtonReadMore,
  MentoringModalCloseBtn,
  MentoringModalContainer,
  MentoringModalContent,
  MentoringModalDescription,
  MentoringModalHeader,
  MentoringModalImage,
  MentoringModalOverlay,
  MentoringModalWrapper
} from './styled.components';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import MentoringModalPopupImage from '../../../images/modals/mentoring-modal-popup-image.jpg';

export default function MentoringModal() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const setCustomCookie = (name, value, days) => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = `${name}=${encodeURIComponent(
      value
    )}; expires=${expires}; path=/`;
  };

  useEffect(() => {
    if (typeof document === 'undefined') return;
    if (typeof window === 'undefined') return;
    const modalShown = document.cookie
      .split('; ')
      .find((row) =>
        row.startsWith(`mentoringModalShown_${window.location.pathname}=`)
      );
    if (!modalShown) {
      setTimeout(() => {
        setIsOpen(true);
      }, 10000);
    }
  }, []);

  if (typeof document === 'undefined') return null;
  if (typeof window === 'undefined') return null;

  const handleClose = () => {
    setIsOpen(false);
    const pathname = window?.location?.pathname ?? '';
    if (!pathname) return;
    setCustomCookie(`mentoringModalShown_${pathname}`, 'true', 30);
  };

  if (!isOpen) return null;

  return (
    <MentoringModalOverlay>
      <div
        id="modal-bg-click-register"
        style={{
          width: '100%',
          height: '100%',
          position: 'fixed',
          top: '0',
          left: '0',
          zIndex: '99'
        }}
        onClick={handleClose}></div>
      <MentoringModalWrapper>
        <MentoringModalCloseBtn onClick={handleClose}>
          <CloseBtnSvg />
        </MentoringModalCloseBtn>
        <MentoringModalContainer>
          <MentoringModalContent>
            <Trans
              i18nKey="find-your-co-funder-and-build-a-startup"
              parent={MentoringModalHeader}
              components={{
                blueheader: <b />,
                lowheader: <div />
              }}
            />
            <MentoringModalDescription>
              {t('join-innovations-hub-incubation-program')}
            </MentoringModalDescription>
            <MentoringModalButtonReadMore
              onClick={handleClose}
              to={`${
                i18n.language === 'pl'
                  ? '/program-inkubacyjny'
                  : '/en/program-inkubacyjny'
              }`}>
              {t('read-more-popup')}
            </MentoringModalButtonReadMore>
          </MentoringModalContent>
          <MentoringModalImage
            src={MentoringModalPopupImage}
            alt="mentoring-modal"
          />
        </MentoringModalContainer>
      </MentoringModalWrapper>
    </MentoringModalOverlay>
  );
}

const CloseBtnSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none">
      <g clip-path="url(#clip0_100_74)">
        <path
          d="M24 8L8 24"
          stroke="#0F5575"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 8L24 24"
          stroke="#0F5575"
          stroke-width="2.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_100_74">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
