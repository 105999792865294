import styled from 'styled-components';

export const WhyContainer = styled.div`
  display: flex;
  padding: 80px 0px;
  justify-content: space-between;
  align-items: center;
  align-items: stretch;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const WhyLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  width: 50%;

  .why-image {
    max-width: 405px;
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
    text-align: center;
    margin-bottom: 32px;

    .why-image {
      margin: auto;
    }
  }
`;

export const WhyRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 50%;

  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;
