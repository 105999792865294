import {
  AmiCircleBorderedImage,
  AmiContainer,
  AmiSection,
  AmiSectionContainer,
  AmiSectionHeader,
  Text
} from '../../styled.components';
import { Trans, useTranslation } from 'react-i18next';

import GrafikaPromo from '../../../../images/ami/grafika_promo.png';
import React from 'react';
import { SectionWrapper } from '../../../../components/global/flexbox';

export default function About() {
  const { t } = useTranslation('ami');
  return (
    <>
      <AmiSection className="what-is">
        <SectionWrapper>
          <AmiSectionContainer className="p-60">
            <AmiContainer customWidth="580px" className="gap-24 custom-w">
              <AmiSectionHeader className="purple text-left">
                {t('projectDescription.title')}
              </AmiSectionHeader>
              <Text>{t('projectDescription.text')}</Text>
            </AmiContainer>
            <AmiCircleBorderedImage src={GrafikaPromo} alt="Promo" />
          </AmiSectionContainer>
        </SectionWrapper>
      </AmiSection>
      <AmiSection className="about">
        <SectionWrapper>
          <AmiSectionContainer className="gap-69 p-60">
            <iframe
              style={{ maxWidth: '710px', width: '100%' }}
              height="400"
              src="https://www.youtube.com/embed/p5ZC3nZr-Kg?si=ltmZCHzMdscn9zWY"
              title="Poznaj Akademię Młodego Innowatora"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              reffererPolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
            <AmiContainer className="gap-18 custom-w" customWidth="400px">
              <Text className="white bold montserrat s-18">
                {t('projectDescription.collaborationText')}
              </Text>
              <Text className="white s-18">
                <Trans
                  i18nKey="projectDescription.ctaText"
                  components={{
                    joinbtn: (
                      <a
                        href="#"
                        rel="noreferrer"
                        style={{
                          color: '#ffffff',
                          textDecoration: 'underline'
                        }}
                      />
                    )
                  }}
                />
              </Text>
            </AmiContainer>
          </AmiSectionContainer>
        </SectionWrapper>
      </AmiSection>
    </>
  );
}
