import React from 'react';
import LinkedinIcon from './slider/svg/linkedinn-icon';
import MailIcon from './slider/svg/mail-icon';

const FoundationManagementSection = ({ management, t }) => {
  return (
    <div>
      <div className="team-management-members-background">
        <h2>{t`foundation-directorate`}</h2>
        <div className="container managment-members-grid">
          {management.members?.map((member) => (
            <div className="team-management-member" key={member.name}>
              <img
                src={member.photo?.file.url}
                alt={`${member.photo?.title} - profile`}
              />
              <div className="management-member-text-ctn">
                <h3>{member.name}</h3>
                <h4>{member.role}</h4>
                <div className="team-management-member-contact-wrapper">
                  {member?.email && (
                    <a
                      className="team-management-member-icon-ctn"
                      href={`mailto:${member?.email}`}
                    >
                      <MailIcon />
                      <p>{member?.email}</p>
                    </a>
                    )}
                  {member?.linkedin && (
                    <a
                      className="team-management-member-icon-ctn"
                      href={member?.linkedin}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkedinIcon />
                      <p className="linkedin-link-wrapper">{member?.linkedin.substring(25)}</p>
                    </a>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FoundationManagementSection;
